import React from 'react';
import Seo from '../components/molecules/Seo';
import { graphql } from 'gatsby';
import ProductGroup from '../components/organisms/ProductGroup';
import styled from 'styled-components';

const Holder = styled.div`
  .product-group {
    border: none;
    padding: 0;
    @media( ${props => props.theme.breakpoints.md} ) {
      border: 1px dashed;
      padding: 2rem 2rem 0 2rem;
    }
  }
`;


function ProductsPage( { data } ) {

  return (
    <Holder>
      <Seo title="Products" description="Shop our many valuable and magic T-shirts and books here."/>
      <h1>Products</h1>
      <ProductGroup products={data.allEtsyListing.nodes} title={false} viewAllLink={false}/>
    </Holder>
  )
}

export default ProductsPage;

export const productPageQuery = graphql`
    query ProductPageQuery {
        allEtsyListing(sort: {fields: featured_rank, order: ASC}) {
          nodes {
            title
            url
            currency_code
            price
            description
            id
            featured_rank
            taxonomy_path
            childrenEtsyListingImage {
              childFile {
                childImageSharp {
                  gatsbyImageData( 
                    breakpoints: 1000
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
    }
`;

